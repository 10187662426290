<template>
  <div id="body">
    <!-- 头部 -->
    <div class="page-header">
      <!-- 导航 -->
      <TopBar class="navi" :activeIndex="'2'"/>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
// 导航
import TopBar from './common/TopBar.vue'

export default {
  name: "Profile",
  components: {
    'TopBar': TopBar,
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>
div#body {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}
div.page-header {
  position: relative;
  top: 0;
  max-width: 1920px;
  margin: 0 auto;
}
/* 导航 */
.navi {
  position: absolute;
  width: 100%;
  z-index: 200;
}
</style>